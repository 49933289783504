.ContaceData {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  box-sizing: border-box;
  padding: 10px;
}

@media (min-width: 600px) {
  .ContaceData {
    width: 500px;
  }
}
